<template>
  <div class="wrapper">
    <h5>Sortiraj</h5>
    <div class="filter-values">
      <input
        type="radio"
        class="radio-button"
        name="sort"
        @click="sort()"
      />
      <!-- <div class="radio-button"></div> -->
      <span>Standardno</span>
    </div>
    <div class="filter-values">
      <input
        type="radio"
        class="radio-button"
        name="sort"
        @click="sort('priceDesc')"
      />
      <!-- <div class="radio-button"></div> -->
      <span>Po ceni - opadajuce</span>
    </div>
    <div class="filter-values">
      <input
        type="radio"
        class="radio-button"
        name="sort"
        @click="sort('priceAsc')"
      />
      <!-- <div class="radio-button"></div> -->
      <span>Po ceni - rastuce</span>
    </div>
    <h5 style="margin-top: 55px;">Kategorije</h5>
    <div 
        class="filter-values"
    >
        <input
          type="checkbox"
          class="checkbox" 
          :checked="filters.categories.length === 0"
          @change="onSelectAllCategories()"
        />
        <span>Sve</span>
    </div>
    <div 
        v-for="category in categories"
        :key="category.id"
        class="filter-values"
    >
        <input
          type="checkbox"
          class="checkbox"
          :checked="filters.categories.includes(category.id)"
          @change="onCategorySelect($event, category.id)"
        />
        <span>{{category.name}}</span>
    </div>
    <h5 style="margin-top: 55px;">Akcija</h5>
    <div 
        class="filter-values"
    >
        <input
          type="checkbox"
          class="checkbox" 
          @change="onAction($event)"
        />
        <span>Trenutno na akciji</span>
    </div>
    <h5 style="margin-top: 55px;">Obrisani</h5>
    <div class="filter-values">
      <input type="checkbox" class="checkbox" @change="onDeleted($event)">
      <span>Prikazi obrisane</span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import { computed } from "vue";
export default {
    props: {
      filters: Object
    },
    emits: ["sort", "action", "category", "showDeleted"],
    setup(props, context) {
        const store = useStore();

        const categories = computed(() => {
            return store.getters.getCategories;
        });

        const sort = (direction = '') => {
            context.emit("sort", direction);
        }

        const onAction = (event) => {
          context.emit("action", event.target.checked)
        }

        const onCategorySelect = (event, categoryId) => {
          context.emit("category", {checked: event.target.checked, categoryId: categoryId});
        }

        const onSelectAllCategories = () => {
          context.emit("selectAllCategories");
        }

        const onDeleted = (event) => {
          context.emit("showDeleted", event.target.checked);
        }

        return {
            categories,
            sort,
            onAction,
            onCategorySelect,
            onSelectAllCategories,
            onDeleted
        }
    },
}
</script>

<style lang="scss" scoped>
.filter-values {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.checkbox {
  margin-right: 8px;
  cursor: pointer;
  margin-left: 2px;
  box-shadow: none !important;
  width: 20px;
  height: 20px;
}

.radio-button {
  margin-right: 8px;
  cursor: pointer;
  margin-left: 2px;
  box-shadow: none !important;
  width: 20px;
  height: 20px;
}
</style>